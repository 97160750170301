<template>
    <v-container>
        <div @click="navigate('home')" class="logo pa-5">
          <img src="../assets/icon.png" alt="mvs logo" width="100px" height="80px">
        </div>
        <div class="logincard">
            <p class="signin">Forgot Password</p>
            <small>
                Enter the email address associated with your MVS account.
            </small>
            <v-form>
        <v-container class="mt-8">
        <v-row>
            <v-col
            cols="12"
            sm="12"
            >
           <v-text-field
            background-color='#c9ebff'
            shaped
              autofocus
            required
            :error-messages="emailErrors"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            v-model="email"
            label="Enter your Email Address"
            filled
           ></v-text-field>
            </v-col>
        </v-row>
        </v-container>
        <Loading v-if="Loading"/>
        <div class="formcard">
         <v-btn
        large
        @click="RestPassword()"
        dark
        class="pl-10 pr-10"
        color="#1c63ba"
        >
        submit
        </v-btn>
        <p class="mt-6">Remember your password now ?<strong class="signup" @click="navigate('login')">Sign in</strong> </p>       
        </div>
        </v-form>
        </div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="390"
    >
      <v-card>
        <v-card-title class="headline center--text">
         Enter Code sent to your Email
        </v-card-title>
        <v-card-text>
              <v-text-field
            background-color='#c9ebff'
            shaped
            v-model="code"
            label="Enter your Verification Code"
            filled
           ></v-text-field>
        <v-text-field
            background-color='#c9ebff'
            shaped
            v-model="password"
            label="Enter New Password"
            filled
           ></v-text-field>
        </v-card-text>
             <Loading v-if="Loading" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            text
            @click="dialog = false"
          >
            Resend
          </v-btn>
          <v-btn
            dark
            color="green darken-1"
            @click="VerifyCode()"
          >
           Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
</template>
<script>
import {RestPasswordByEmail, ConfirmPasswordBySentToEmail} from '../APIs'
import axios from "axios";
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Loading from '../components/Loading'
export default {
      mixins: [validationMixin],
    validations: {
      email: { required, },
    },
          components:{ 
          Loading,
    },
    metaInfo: {
    title: `Rest Password`,
    meta: [
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'Ecommerce'}
    ]
  },
     data: () => ({
         dialog:false,
         email:"",
         code:"",
         password:"",
      icons: [
        'mdi-facebook',
        'mdi-google',
      ],
      color:[
          '#1c63ba',
          'red'
      ]
    }), 
    computed:{
         Loading() {
          return this.$store.getters.Loading;
        },
        emailErrors () {
          const errors = []
        if (!this.$v.email.$dirty) return errors
          !this.$v.email.required && errors.push('email is required.')
          return errors
      },
    },
    methods:{
        navigate(payload){
           this.$router.push({name:payload});
        },
        RestPassword(){
            if(this.email == ''){
                this.$store.commit("setSnackbar", true)
                this.$store.commit("setServerMessage", "Fill All The Required Field")
                return
            }
             this.$store.commit("setLoading", true)
          axios.put(`${RestPasswordByEmail}`,{
            email:this.email,
          })
          .then(res => {  
               this.dialog = true,  
              console.log(res.data, "PPP") 
          })
          .catch(err => {
            console.log(err.response.data, "Rest Password: ERR")
            this.$store.commit("setSnackbar", true);
            this.$store.commit("setServerMessage", `Err: ${err.response.data.message}`)
          })
          .finally(()=>{
             this.$store.commit("setLoading", false)
          })
        },
        VerifyCode(){
          console.log( "emal",this.email, this.password, this.code)
             if(this.email == '' || this.password == '' || this.code == ''){
                this.$store.commit("setSnackbar", true)
                this.$store.commit("setServerMessage", "Fill All The Required Field")
                return
            }
            this.$store.commit("setLoading", true)
          axios.put(`${ConfirmPasswordBySentToEmail}`,{
            email:this.email,
            password:this.password,
            code:this.code,
          })
          .then(res => {  
              console.log(res.data, "VerifyCode")
                this.dialog = false,  
                this.$router.push({name:'login'});
                this.$store.commit("setSnackbar", true);
                this.$store.commit("setServerMessage", "Password Successfully Change")
          })
          .catch(err => {
            console.log(err.response.data, "Rest Password: ERR")
            this.$store.commit("setSnackbar", true);
            this.$store.commit("setServerMessage", `Err: ${err.response.data.message}`)
          })
          .finally(()=>{
             this.$store.commit("setLoading", false)
          })
        }
    }
}
</script>
<style scoped>
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.logincard{
  margin: 20px 318px 212px 314px;
  padding: 29px;
  border-radius: 20px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
}
.signin{
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #1156aa;
}
.forgetPassword{
    text-align:right;
}
.signup{
    color: #d9ab07;
     cursor: pointer;
}
.text{
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  text-decoration-line: underline;
  color: #1c63ba;
}
.formcard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.oauth{
    display: flex;
    margin: 0px;
}
@media only screen and (max-width: 700px) {
  .logincard{
  margin: 4px 18px 22px 14px;
  padding: 29px;
  border-radius: 20px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
}
}
</style>